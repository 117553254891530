// import { Oval } from "react-loader-spinner";
import { useHomePage } from "./hooks/useHomePage";
import MainLayout from "../../containers/MainLayout";
import DashBoardBox from "./components/DashBoardBox";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TextField,
  Card,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
} from "@mui/material";
import car from "../../assets/images/car_entry_exit.png";
import bike from "../../assets/images/bike_entry_exit.png";
import companyLogo from "../../assets/images/company-logo.png";
// eslint-disable-next-line
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
// import useVehicleType from "../entry-exit/hooks/useVehicleType";
// import moment from "moment";

const HomePage = () => {
  const {
    today,
    parkingType,
    // Loading,
    parkingTypes,
    formValues,
    sevenDaysAfter,
    dashboardData,
    weeklyGraphByCarDetails,
    weeklyGraphByBikeDetails,
    monthlyGraphByCarDetails,
    monthlyGraphByBikeDetails,
    weeklyGraphByParkingTypeDetails,
    monthlyGraphByParkingTypeDetails,
    handleSelectOnChange,
    setFormValues,
  } = useHomePage();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#576782",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  // const { twoWheelerId, fourWheelerId } = useVehicleType();
  // const Loader = (
  //   <>
  //     <Oval
  //       height={47}
  //       width={30}
  //       color="#344767"
  //       wrapperStyle={{}}
  //       wrapperClass=""
  //       visible={true}
  //       ariaLabel="oval-loading"
  //       secondaryColor="#4fa94d"
  //       strokeWidth={2}
  //       strokeWidthSecondary={2}
  //     />
  //   </>
  // );

  const data = [
    {
      image: "entry_dsh",
      text: "Total Entry",
      count: dashboardData?.totalEntryCount,
    },
    {
      image: "exit_dsh",
      text: "Total Exit",
      count: dashboardData?.totalExitCount,
    },
    {
      image: "parking_dsh",
      text: "Vehicle on Premises",
      count: dashboardData?.vehicleOnPrimeses,
    },
    {
      image: "rupe_dsh",
      text: "Total Amount Collected",
      count: dashboardData?.parkingAmount,
    },
    {
      image: "rupe_dsh",
      text: "Total Refund",
      count: dashboardData?.refundCount,
    },
    {
      image: "rupe_dsh",
      text: "Total Refund Amount",
      count: dashboardData?.refundAmount,
    },
    {
      image: "rupe_dsh",
      text: "Total Premium",
      count: dashboardData?.premiumCount,
    },
    {
      image: "rupe_dsh",
      text: "Total Premium Amount",
      count: dashboardData?.premiumAmount,
    },
    {
      image: "rupe_dsh",
      text: "Total Valet",
      count: dashboardData?.valetCount,
    },
    {
      image: "rupe_dsh",
      text: "Total Valet Amount",
      count: dashboardData?.valetAmount,
    },
    {
      image: "rupe_dsh",
      text: "Total VIP",
      count: dashboardData?.vipCount,
    },
    {
      image: "rupe_dsh",
      text: "Total Complimentary",
      count: dashboardData?.complimentaryCount,
    },

    {
      image: "rupe_dsh",
      text: "Total Complimentary Amount",
      count: dashboardData?.complimentaryAmount,
    },

    // {
    //   image: "bike_dsh",
    //   text: "Bikes Entry",
    //   count: Loading === true ? Loader : dashboardData?.totalBikeEntry,
    // },
    // {
    //   image: "bike_dsh",
    //   text: "Bikes Exit",
    //   count: Loading === true ? Loader : dashboardData?.totalBikeExit,
    // },
    // {
    //   image: "parking_dsh",
    //   text: "Bikes on Premises",
    //   count: Loading === true ? Loader : dashboardData?.bikeOnPrimeses,
    // },
    // {
    //   image: "rupe_dsh",
    //   text: "Bikes Amount Collected",
    //   count: Loading === true ? Loader : dashboardData?.bikeAmount,
    // },
    // {
    //   image: "bike_dsh",
    //   text: "Bike Less than 15",
    //   count: Loading === true ? Loader : dashboardData?.bikeLessThan15,
    //   url: `/bikes-refund-report/${fourWheelerId}`,
    // },
    // {
    //   image: "bike_dsh",
    //   text: "Bikes Complimentary",
    //   count: Loading === true ? Loader : dashboardData?.bikeComplimentry,
    // },
    // {
    //   image: "bike_dsh",
    //   text: "Bikes VIP Count",
    //   count: Loading === true ? Loader : dashboardData?.bikeVIPCount,
    // },
    // {
    //   image: "car_dsh",
    //   text: "Cars Entry",
    //   count: Loading === true ? Loader : dashboardData?.totalCarEntry,
    // },
    // {
    //   image: "car_dsh",
    //   text: "Cars Exit",
    //   count: Loading === true ? Loader : dashboardData?.totalCarExit,
    // },
    // {
    //   image: "parking_dsh",
    //   text: "Cars on Premises",
    //   count: Loading === true ? Loader : dashboardData?.carOnPrimeses,
    // },
    // {
    //   image: "rupe_dsh",
    //   text: "Cars Amount Collected",
    //   count: Loading === true ? Loader : dashboardData?.carAmount,
    // },
    // {
    //   image: "car_dsh",
    //   text: "Car Less than 15",
    //   count: Loading === true ? Loader : dashboardData?.carLessThan15,
    //   url: `/cars-refund-report/${twoWheelerId}`,
    // },

    // {
    //   image: "car_dsh",
    //   text: "Cars VIP count",
    //   count: Loading === true ? Loader : dashboardData?.carVIPCount,
    // },
    // {
    //   image: "car_dsh",
    //   text: "Cars Complimentary",
    //   count: Loading === true ? Loader : dashboardData?.carCOmplimentry,
    // },
  ];

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const labelsForWeek = weeklyGraphByCarDetails?.day?.map(
    (obj) => Object.values(obj)[0]
  );

  const monthlyGraphByVehicleType = {
    labels: labels,
    datasets: [
      {
        label: "Car",
        backgroundColor: "#549F7A",
        borderColor: "#549F7A",
        data: monthlyGraphByCarDetails?.data,
      },
      {
        label: "Bike",
        backgroundColor: "#349DB4",
        borderColor: "#349DB4",
        data: monthlyGraphByBikeDetails?.data,
      },
    ],
  };

  const weeklyGraphByVehicleType = {
    labels: labelsForWeek,
    datasets: [
      {
        label: "Car",
        backgroundColor: "#549F7A",
        borderColor: "#549F7A",
        data: weeklyGraphByCarDetails?.data,
      },
      {
        label: "Bike",
        backgroundColor: "#349DB4",
        borderColor: "#349DB4",
        data: weeklyGraphByBikeDetails?.data,
      },
    ],
  };

  const monthlyGraphByParkingType = {
    labels: labels,
    datasets: [
      {
        label: parkingType.parkingTypeName,
        backgroundColor: "#9c211b",
        borderColor: "#9c211b",
        data: monthlyGraphByParkingTypeDetails?.data,
      },
    ],
  };

  const weeklyGraphByParkingType = {
    labels: labelsForWeek,
    datasets: [
      {
        label: parkingType.parkingTypeName,
        backgroundColor: "#9c211b",
        borderColor: "#9c211b",
        data: weeklyGraphByParkingTypeDetails?.data,
      },
    ],
  };

  return (
    <>
      <MainLayout>
        <Typography className="text-[#252F40] !mb-2">
          <span className="text-[#8C8C8C]">Parkezy / </span> Dashboard
        </Typography>
        <Box className="relative" sx={{ background: "none" }}>
          <Grid
            container
            sx={{
              background: "linear-gradient(310deg, #576782 0%, #A8B8D8 100%)",
              height: "186px",
              borderRadius: "10px",
              mt: 5,
            }}
            className="items-center"
          >
            <Grid item lg={6}>
              <Box className="!text-[white] pl-8">
                <Typography variant="h5" className="!font-medium">
                  Welcome to{" "}
                  <span className="!font-bold">
                    PARK<span className="!text-[33px]">ezy</span>
                  </span>
                </Typography>
              </Box>
              <Box className="bg-[white] !max-w-[250px] !rounded-lg !ml-8 flex justify-center items-center">
                <img src={companyLogo} alt="" width={100} height="auto" />
              </Box>
            </Grid>

            <Grid item lg={6}>
              <img
                src={car}
                alt="car"
                style={{ top: "-99px", right: "1%" }}
                className="absolute hidden md:flex"
              />

              <img
                src={bike}
                height={200}
                alt="bike"
                style={{ right: "0px", top: "-22px" }}
                className="absolute hidden md:flex"
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3} className="!mt-4 ">
          {data.map((val) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DashBoardBox
                  image={val.image}
                  text={val.text}
                  count={val.count}
                  url={val?.url}
                />
              </Grid>
            );
          })}
        </Grid>

        <Divider light className="!mt-10" />
        {/* TABLE START  */}
        <Card>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Vehicle Type</StyledTableCell>
                  <StyledTableCell align="center">Entry</StyledTableCell>
                  <StyledTableCell align="center">Exit</StyledTableCell>
                  <StyledTableCell align="center">On Premise</StyledTableCell>
                  <StyledTableCell align="center">
                    Less Than 15 Minits
                  </StyledTableCell>
                  <StyledTableCell align="center">VIP</StyledTableCell>
                  <StyledTableCell align="center">
                    Complimentary
                  </StyledTableCell>
                  <StyledTableCell align="center">Premium</StyledTableCell>
                  <StyledTableCell align="right">PremAmount</StyledTableCell>
                  <StyledTableCell align="center">Valet</StyledTableCell>
                  <StyledTableCell align="right">Valet Amount</StyledTableCell>
                  <StyledTableCell align="right">Total Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {dashboardData?.vehicles?.length > 0 ? (
                    <>
                      {dashboardData?.vehicles?.map((row, id) => {
                        return (
                          <TableRow key={row?.id}>
                            <TableCell
                              align="center"
                              style={{ color: "#344767", fontWeight: "bold" }}
                            >
                              {row?.vehicleName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.totalEntry}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.totalExit}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.onPremises}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.lessThan15}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.vipCount}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.complimentary}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.premiumCount}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ color: " #344767" }}
                            >
                              {row?.premiumAmount?.toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: " #344767" }}
                            >
                              {row?.valetCount}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ color: " #344767" }}
                            >
                              {row?.valetAmount?.toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ color: " #344767", fontWeight: "bold" }}
                            >
                              {row?.amount?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableCell colSpan={13}>
                      <Typography className="flex items-center justify-center">
                        No data found!
                      </Typography>
                    </TableCell>
                  )}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {/* TABLE ENDS  */}
        <Grid conatiner className="mt-3 mb-5">
          <Grid item>
            <Typography fontWeight="bold">Monthly Report</Typography>
          </Grid>
        </Grid>

        <Grid container className="mt-5 mb-5" gap={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Parking Type</InputLabel>
              <Select
                labelId="Parking Type"
                label="Parking type*"
                name="parkingTypeId"
                value={parkingType.parkingTypeId}
                defaultValue={parkingType.parkingTypeId}
                onChange={handleSelectOnChange}
              >
                {parkingTypes?.map((obj, i) => {
                  if (obj.deleted === false) {
                    return (
                      <MenuItem value={`${obj.id},${obj.name}`} key={obj.i}>
                        {obj.name}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          className="mt-5 mb-5 !justify-center !items-center"
        >
          <Grid item sm={12} md={6} lg={6}>
            <Bar data={monthlyGraphByParkingType} />
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <Bar data={monthlyGraphByVehicleType} />
          </Grid>
        </Grid>

        <Grid conatiner className="mt-3 mb-5">
          <Grid item>
            <Typography fontWeight="bold">Weekly Report</Typography>
          </Grid>
        </Grid>

        <Grid container className="mt-5 mb-5" spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                id="from-date"
                label="From To"
                variant="outlined"
                type="date"
                focused
                size="small"
                value={formValues.fromDate}
                defaultValue={formValues.fromDate}
                InputProps={{
                  inputProps: {
                    max: today,
                  },
                }}
                onChange={(e) => {
                  setFormValues({ ...formValues, fromDate: e.target.value });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                id="to-date"
                label="Date To"
                variant="outlined"
                type="date"
                focused
                size="small"
                value={sevenDaysAfter}
                defaultValue={sevenDaysAfter}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className="mt-5 mb-5 !justify-center !items-center"
        >
          <Grid item sm={12} md={6} lg={6}>
            <Bar data={weeklyGraphByParkingType} />
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <Bar data={weeklyGraphByVehicleType} />
          </Grid>
        </Grid>
      </MainLayout>
    </>
  );
};

export default HomePage;
