import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { Stack } from "@mui/system";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Dropzone from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CancelIcon from "@mui/icons-material/Cancel";

const VehicleMasterForm = ({
  open,
  formValues,
  isDisabled,
  errorMessage,
  handleChange,
  handleClose,
  findDuplicateVehicleType,
  files,
  setFiles,
  setFormValues,
}) => {

  const thumb = {
    width: "130px",
    height: "auto",
  };
  const img = {
    objectFit: "contain",
    width: "100%",
    height: "auto",
    margin: "0px",
  };

  const vehicleImage = (
    <div style={thumb} key={files.vehicleImage[0]?.preview}>
      <img
        alt="Vehicle"
        src={
          files.vehicleImage[0]?.preview
            ? files.vehicleImage[0]?.preview
            : formValues?.fileUrl
        }
        style={img}
      />
      <span
        onClick={() => {
          setFiles({ ...files, vehicleImage: "" });
          setFormValues({ ...formValues, fileUrl: "" });
        }}
        className="cursor-pointer"
      >
        <CancelIcon className="absolute top-0 right-0 m-1" />
      </span>
    </div>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            {!formValues?.id ? "Add" : "Edit"}
          </DialogTitle>

          <CancelRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent>
          <Stack spacing={4} paddingLeft={4} paddingRight={4} paddingBottom={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                required
                name="vehicletype"
                type="text"
                id="outlined-required"
                label="Name"
                size="small"
                onChange={handleChange}
                value={formValues ? formValues.vehicletype : ""}
              />
              <TextField
                required
                name="description"
                type="text"
                id="outlined-required"
                label="Description"
                size="small"
                value={formValues ? formValues.description : ""}
                onChange={handleChange}
              />
            </Stack>

            <Grid container gap={4} mt={4} className="flex justify-center">
              <Grid item xs={12} md={5} lg={3.5}>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    setFiles({
                      ...files,
                      vehicleImage: acceptedFiles.map((file) =>
                        Object.assign(file, {
                          preview: URL.createObjectURL(file),
                        })
                      ),
                    })
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <Typography
                        className="text-center"
                        color="#576782"
                        mb={1}
                      >
                        Upload vehicle image{" "}
                        <span style={{ color: "#C01111" }}>*</span>
                      </Typography>
                      <section className="containers">
                        <section className="text-center mb-0">
                          {files.vehicleImage === "" && formValues?.fileUrl === "" ? (
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <section className="card-text mt-2">
                                <CloudUploadOutlinedIcon
                                  sx={{ color: "#576782" }}
                                />
                                <Typography color="#576782">
                                  Drag and Drop here
                                </Typography>
                              </section>
                              <Typography color="#576782">or</Typography>
                              <Button
                                variant="contained"
                                className="btn btn-primary mt-2"
                                sx={{
                                  background:
                                    "linear-gradient(310deg, #141727 0%, #3A416F 100%);",
                                  mb: 2,
                                }}
                              >
                                Select File
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                          {(files.vehicleImage[0]?.preview ||
                            formValues?.fileUrl !== "") && (
                            <aside className="absolute">{vehicleImage}</aside>
                          )}
                        </section>
                      </section>
                    </>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={12} md={5} lg={3.5}></Grid>
            </Grid>

            <Typography variant="subtitle2" className="text-[#DD2121]">
              {errorMessage}
            </Typography>

            <Button
              disabled={!isDisabled}
              onClick={findDuplicateVehicleType}
              sx={{
                textTransform: "none",
                background: !isDisabled
                  ? "gray"
                  : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                color: "white",
                "&:hover": {},
              }}
            >
              {!formValues?.id ? "Submit" : "Update"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VehicleMasterForm;
