// export const BASE_URL = "http://65.2.99.81";
// export const BASE_URL = "http://13.234.109.98";
export const BASE_URL = "http://13.234.109.98";


export const LOGIN = `${BASE_URL}/user/oauth/token`;

export const PARKEZY_SERVICE_URL = `${BASE_URL}/parkezy`;
export const INFRASTRUCTURE_SERVICE_URL = `${BASE_URL}/infrastructure`;
// export const SERVICE_URL_3 = `${BASE_URL}:10050`;
export const AUTH_SERVICE_URL = `${BASE_URL}/user`;
export const AUTH_SERVICE_URL_ADMIN = `${BASE_URL}/user/v1/admin`;
export const EMPLOYEE_SERVICE_URL = `${BASE_URL}/employee`;
export const PROFILE_SERVICE_URL = `${BASE_URL}/profile`;
export const FILE_SERVICE_URL = `${BASE_URL}/file`;

export const ATTENDANCE_SERVICE_URL = `${BASE_URL}/attendance`;

//File upload
export const UPLOAD_FILE = `${FILE_SERVICE_URL}/v1/attachment`;
export const SAVE_DOCUMENT = `${PROFILE_SERVICE_URL}/v1/document`;
export const SAVE_USER_DOCUMENT = `${PROFILE_SERVICE_URL}/v1/user/{userId}/document`;

//Vehicle Entry
export const GET_ACTIVE_ENTRY_BOOTH = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/entry-booth/status/false`;
export const GET_ACTIVE_EXIT_BOOTH = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/exit-booth/status/false`;
export const VEHICLE_ENTRY = `${PARKEZY_SERVICE_URL}/v1/staff/tenant/{tenantId}/location/{locationId}/parking`;
export const GET_CAMERA_READING = `${PARKEZY_SERVICE_URL}/v1/staff/tenant/{tenantId}/location/{locationId}/parking/anpr/recent-vehicle-detection`;
export const GET_BASE_FEES = `${PARKEZY_SERVICE_URL}/v1/staff/tenant/{tenantId}/location/{locationId}/fees/base-fees`;
export const GET_PAYMENT_MODE = `${PARKEZY_SERVICE_URL}/v1/admin/payment-mode/active/false`;
export const ENTRY_BOOTH_INACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/entry-booth/{resotreId}`;
export const ENTRY_BOOTH_ACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/entry-booth/restore/{resotreId}`;
export const GET_SLOT_COUNT_URL = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot/get-slot-count/{vehicleTypeId}/{parkingTypeId}`;

//Vehicle Exit
export const GET_ENTRY_DETAILS = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/vrn`;
export const GET_ENTRY_DETAILS_NEW = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/id`;
export const VEHICLE_EXIT = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking`;
export const PARKING_AMOUNT = `${PARKEZY_SERVICE_URL}/v1/staff/tenant/{tenantId}/location/{locationId}/parking`;
export const GET_FULL_PARKING_AMOUNT = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/fees-master/get-parking-amount/{parkingTypeId}/{vehicleTypeId}`;
export const EXIT_BOOTH_ACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/exit-booth/restore/{resotreId}`;
export const EXIT_BOOTH_INACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/exit-booth/{resotreId}`;

// Dashboard Data
export const GET_TOTAL_ENTRY = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/reports/daily-statistics`;
// export const GET_DASHBOARD_DETAILS_BY_VEHICLE_TYPE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/reports/daily-statistics/today-count-by-vehicle-type`;
// export const GET_TODAY_DASHBOARD_DETAILS = `${PARKEZY_SERVICE_URL}/v1/admin/parking-status/get-dashboard/{tenantId}/{locationId}`; remove (01-12-2023)
// export const GET_TODAY_DASHBOARD_DETAILS = `${PARKEZY_SERVICE_URL}/v1/admin/parking-status-report/get-dashboard/{tenantId}/{locationId}`;
export const GET_TODAY_DASHBOARD_DETAILS = `${PARKEZY_SERVICE_URL}/v1/admin/parking-status-report/get-dashboard-tenantId-locationId?tenantId={tenantId}&locationId={locationId}`;

//Fee Master
export const GET_ALL_FEE_MASTER_DATA = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/fees-master`;
export const ADD_FEE_MASTER_URL = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/fees-master`;
export const UPDATE_FEE_MASTER_URL = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/fees-master`;
export const GET_FEE_MASTER_BY_ID = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/fees-master`;
export const FEES_MASTER_INACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/fees-master/{id}`;
export const FEES_MASTER_ACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/fees-master/restore/{restoreId}`;

//Parking type
export const GET_PARKING_TYPES = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking-type`;

// export const GET_ALL_PARKING_TYPE = `${SERVICE_URL}/v1/staff/tenant/${tenantId}/location/${locationId}/parking-type`
export const PARKING_TYPE_INACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking-type/{id}`;
export const PARKING_TYPE_ACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking-type/restore/{id}`;

// Vehicle master
export const VEHICLE_TYPE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/vehicle-type`;
export const PUT_VEHICLE_REPORT = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/vehicle-type`;

// New Parking Type
export const GET_PARKING_TYPE1 = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking-type`;

//PaymentMode
export const GET_ALL_PAYMENT_MODE_DATA = `${PARKEZY_SERVICE_URL}/v1/admin/payment-mode/active/false`;
export const ADD_PAYMENT_MODE = `${PARKEZY_SERVICE_URL}/v1/admin/payment-mode`;

//Entry Booth Master
export const GET_ALL_ENTRY_BOOTH_MASTER_DATA = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/entry-booth`;
export const ADD_ENTRY_BOOTH_MASTER = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/entry-booth`;
export const GET_BY_ENTRY_BOOTH_ID = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/entry-booth`;

//Booth or Gate Master
export const ADD_BOOTH_OR_GATE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/gate`;
export const GET_ALL_BOOTH_OR_GATE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/gate`;
export const GET_BOOTH_OR_GATE_BY_ID = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/gate`;
export const GET_ACTIVE_GATES = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/gate/active-gate-types/{type}`;
export const GATE_MASTER_INACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/`;
export const GATE_MASTER_ACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/`;

//Exit Booth Master
export const GET_ALL_EXIT_BOOTH_MASTER_DATA = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/exit-booth`;
export const ADD_EXIT_BOOTH_MASTER = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/exit-booth`;
export const GET_BY_EXIT_BOOTH_ID = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/exit-booth`;

// Location Master
export const GET_LOCATION_MASTER_DATA = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/`;
export const GET_USER_LOCATION = `${AUTH_SERVICE_URL}/v1/admin/user-location`;
export const GET_ALL_LOCATIONS = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location`;
export const LOCATIONS_STATUS_INACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/delete/{id}`;
export const LOCATIONS_STATUS_ACTIVE = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/restore/{id}`;

// Floor Master
export const GET_FLOORS = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor`;
export const GET_ACTIVE_FLOORS = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/get-all-active/ced4ddb3-16f5-4a51-a7c0-f8da3231619f`;
export const GET_FLOOR = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}`;
export const ADD_FLOOR = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor`;
export const UPDATE_FLOOR = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor`;
export const DELETE_FLOOR = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}`;
export const RESTORE_FLOOR = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/restore/{floorId}`;

// Slot Master
export const GET_SLOTS = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot/get-all`;
// export const GET_SLOTS = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot`;
export const GET_ACTIVE_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot/get-active-alloted-slot/{vehicleId}/{parkingTypeId}`;
export const GET_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot/{slotId}`;
export const ADD_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot`;
export const ALLOT_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot/true/{slotId}`;
export const UNALLOCATE_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/floor/{floorId}/location/{locationId}/slot/false/{slotId}`;
export const UPDATE_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot`;
export const DELETE_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot/{slotId}`;
export const RESTORE_SLOT = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/location/{locationId}/slot/restore/{slotId}`;
export const GET_ALL_SLOTS = `${PARKEZY_SERVICE_URL}/v1/admin/slot-count/{tenantId}/{locationId}/{vehicleId}/{parkingTypeId}`;

export const GET_SLOT_BY_FLOOR = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/floor/{floorId}/slot`;
export const GET_SLOT_BY_ID = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/floor/{floorId}/slot`;

//block Master
export const GET_BLOCKS = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}/block`;
export const GET_BLOCK = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}/block/{blockId}`;
export const ADD_BLOCK = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}/block`;
export const UPDATE_BLOCK = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}/block`;
export const DELETE_BLOCK = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}/block/{blockId}`;
export const RESTORE_BLOCK = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/floor/{floorId}/block/restore/{blockId}`;

// Area master
export const GET_ACTIVE_AREAS = `${INFRASTRUCTURE_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/area`;

//Cash Collect Report
export const ADD_CASH_COLLECT_DATA = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/cash-collection`;
export const GET_CASH_COLLECT_REPORT_DATA = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/cash-collection/report`;
export const GET_CASH_COLLECT_DETAIL_URL = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/cash-collection/cash-collect-details-report`;
export const GET_CASH_COLLECT_DETAILS_VIEW_WITH_PAGINATION = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/cash-collection/get-cash-collect-details-report`;

//vehicle type
export const DELETE_VEHICLE_TYPE = `${PARKEZY_SERVICE_URL}v1/admin/tenant/{tenant}/location/{locationId}/vehicle-type/{id}`;
export const VEHICLE_TYPE_INACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/vehicle-type/{vehicleId}`;
export const VEHICLE_TYPE_ACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/vehicle-type/restore/{restoreId}`;

//Transaction Report
export const GET_ALL_TRANSACTION_DATA = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/reports/daily-statistics/get-day-wise-report`;
export const GET_TRNASACTION_VEHICLE_DATA = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/reports/daily-statistics/get-all-vehicle-report`;
export const GET_TRNASACTION_REPORT_WITH_PAGINATION = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/reports/daily-statistics/get-day-wise-report`;
export const GET_TRNASACTION_REPORT_DETAIL_PAGE_WITH_PAGINATION = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/reports/daily-statistics/get-all-vehicle-report`;
export const TRNASACTION_REPORT_SEARCH = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/reports/daily-statistics/get-vehicle-report-by-vrn`;
export const GET_TRANSACTION_REPORT = `${PARKEZY_SERVICE_URL}/v1/admin/parking-status-report/get-transaction-report/{tenantId}/{locationId}`;

//Shift Management
export const ADD_NEW_SHIFT = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-type`;
export const GET_ALL_SHIFT_DATA = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-type`;
export const GET_BY_SHIFT_ID = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-type`;

//Staff Onboaring
export const POST_STAFF_ONBOARDING = `${AUTH_SERVICE_URL}/v1/user`;
export const EMPLOYEE_SAVE_STAFF_ONBOARDING = `${EMPLOYEE_SERVICE_URL}/v1/admin/{tenantId}/employee`;
export const USER_ADDRESS_SAVE_STAFF_ONBOARDING = `${PROFILE_SERVICE_URL}/v1/user/{userId}/address`;
export const GET_ALL_STAFF_DATA = `${AUTH_SERVICE_URL}/v1/user/get-all`;

//Supervisor onboarding
export const ADD_SUPERVISOR = `${AUTH_SERVICE_URL_ADMIN}/{userId}/supervisor`;

//ID card type and subtype
export const GET_IDCARD_TYPES = `${PROFILE_SERVICE_URL}/v1/document-type/active-document-types`;
export const GET_IDCARD_SUB_TYPES = `${PROFILE_SERVICE_URL}/v1/document-sub-type/active-document-sub-types`;

//Shift Assign

export const GET_SHIFT_ASSIGN_DETAILS = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-assign/get-shift-history`;
export const GET_SHIFT_ASSIGN_DATA = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-assign`;
export const ADD_SHIFT_ASSIGN = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-assign`;
export const GET_BY_SHIFT_ASSIGN = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-assign`;
export const GET_ALL_ACTIVE_SHIFT = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-type/active-shift-types`;
export const GET_ALL_SHIFT_TABLE_DATA = `${ATTENDANCE_SERVICE_URL}/v1/admin/{tenantId}/shift-assign/get-shift-history`;

// Vehicle number suggestion
// export const GET_VEHICLE_NUMBER_SUGGESTION = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/get-vrn/{vrn}`;
export const GET_VEHICLE_NUMBER_SUGGESTION = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/get-vrn/{vrn}/{vehicleTypeId}`;

// Reason for extra charges
export const GET_EXTRA_CHARGE_REASONS = `${PARKEZY_SERVICE_URL}/v1/admin/extra-charges`;

// Designation
export const GET_ALL_DESIGNATION = `${EMPLOYEE_SERVICE_URL}/v1/admin/{tenantId}/designation`;

// Users
export const GET_ALL_ACTIVE_EMPLOYEES = `${EMPLOYEE_SERVICE_URL}/v1/admin/{tenantId}/employee/active-employees`;

//Get refund details report
export const GET_REFUND_REPORTS = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/refund`;

// Graph
export const GET_MONTHLY_GRAPH_BY_VEHICLE_TYPE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/vehicle-type-graph-by-month/{year}/{vehicleType}`;
export const GET_MONTHLY_GRAPH_BY_PARKING_TYPE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/parking-type-graph-by-month/{year}/{parkingType}`;

export const GET_WEEK_GRAPH_BY_VEHICLE_TYPE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/vehicle-type-graph-by-day/{vehicleType}`;
export const GET_WEEK_GRAPH_BY_PARKING_TYPE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/parking-type-graph-by-day/{parkingType}`;

// EXTRA CHARGES
export const EXTRA_CHARGES_DATA_TABLE = `${PARKEZY_SERVICE_URL}/v1/admin/extra-charges`;
export const CREATE_EXTRA_CHARGES = `${PARKEZY_SERVICE_URL}/v1/admin/extra-charges`;
export const STATUS_INACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/extra-charges/`;
export const STATUS_ACTIVE = `${PARKEZY_SERVICE_URL}/v1/admin/extra-charges/restore/`;
export const EXTRA_CHARGES_UPDATE = `${PARKEZY_SERVICE_URL}/v1/admin/extra-charges`;

// STAFF MANAGEMENT EDIT
export const GET_USER_DETAILS = `${AUTH_SERVICE_URL}/v1/user/`;
export const GET_USER_LOCATION_DETAILS = `${AUTH_SERVICE_URL}/v1/admin/user-location/user/`;
export const GET_USER_ADDRESS_DETAILS = `${PROFILE_SERVICE_URL}/v1/user/{id}/address`;
export const GET_USER_DOCUMENT_DETAILS = `${PROFILE_SERVICE_URL}/v1/user/{id}/document/get-document-by-user-id`;
export const GET_USER_ATTACHMENT_DETAILS = `${FILE_SERVICE_URL}/v1/attachment/`;
export const GET_ID_CARD_SUBTYPE = `${PROFILE_SERVICE_URL}/v1/document-sub-type/active-document-sub-types/`;
export const GET_DESIGNATION_DETAILS = `${EMPLOYEE_SERVICE_URL}/v1/admin/{tenantId}/employee/get-user/{id}`;

// STAFF MANAGEMENT UPDATE
export const UPDATE_USER_DETAILS = `${AUTH_SERVICE_URL}/v1/user`;
export const UPDATE_EMPLOYEE_DETAILS = `${EMPLOYEE_SERVICE_URL}/v1/admin/{tenantId}/employee`;
export const UPDATE_DOCUMENT_DETAILS = `${PROFILE_SERVICE_URL}/v1/document-type`;
export const UPDATE_ADDRESS_DETAILS = `${PROFILE_SERVICE_URL}/v1/user/{tenantId}/address`;
export const UPDATE_DOCUMENT_SUBTYPE = `${PROFILE_SERVICE_URL}/v1/document-sub-type`;
export const UPDATE_LOCATION_DETAILS = `${AUTH_SERVICE_URL}/v1/admin/user-location`;
export const UPDATE_DOCUMENT_DETAILS_UPDATE = `${PROFILE_SERVICE_URL}/v1/user/{tenantId}/document`;
export const UPDATE_DOCUMENT_UPDATE = `${AUTH_SERVICE_URL}/v1/document`;

// USER RECEIPT PRINT
export const GET_USER_RECEIPT_PRINT = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{location}/cash-collection/get-daily-cash-collect-by-user/{userId}`;

//Auth
export const CHANGE_PASSWORD = `${AUTH_SERVICE_URL}/v1/user/change-password`;

//Dashboard image
export const SAVE_DASHBOARD_IMAGE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/logo`;
export const UPDATE_DASHBOARD_IMAGE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/logo`;
export const GET_DASHBOARD_IMAGE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/logo`;

//Delete Staff
export const DELETE_USER = `${AUTH_SERVICE_URL}/v1/user/{userId}`;

//Settlement
export const GET_SETTLEMENT_REPORT = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{location}/cash-collection/settlement-report`;

//Complimentary Reports
export const GET_PARKING_TYPE = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/`;
export const GET_COMPLIMENTARY_REPORT = `${PARKEZY_SERVICE_URL}/v1/admin/tenant/{tenantId}/location/{locationId}/parking/complimentary-details`;
